import AlarmIcon from '@components/icons/alarm';
import CalendarMonthIcon from '@components/icons/calendar-month';
import { FreeShip } from '@components/icons/free-ship';
import ChevronRightIcon from '@components/icons/new/chevron-right-icon';
import PercentIcon from '@components/icons/percent';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export const Slogan: React.FC = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const handleRedirectPage = () => {
    router.push('/fee-ship');
  }
  return (
    <div className="i33-slogan flex items-center flex-row">
        <div className="left flex flex-2">
            <div className="free-ship flex items-center justify-between" onClick={handleRedirectPage}>
                <FreeShip /> <span>{t('warranty-commit.free-ship')}</span> <ChevronRightIcon color="#6AA543" />
            </div>
            <div className="service flex">
                <div className="date-ship flex items-center justify-between">
                    <AlarmIcon/> <span> {t('warranty-commit.date-ship')}</span>
                </div>
                <div className="date-complaint flex items-center justify-between">
                    <CalendarMonthIcon /><span>{t('warranty-commit.date-complaint')}</span>
                </div>
                <div className="price-from-owner flex items-center justify-between">
                    <PercentIcon /><span>{t('warranty-commit.price-from-owner')}</span>
                </div>
            </div>
        </div>
        <div className="right flex flex-1 justify-end">
            <div className='flex items-center justify-between'>
                <div className='hotline'><span> {t('i33-support')}</span> <b>{t('i33-support-phone')}</b></div>
            </div>
        </div>
    </div>
  )
}

export default Slogan;